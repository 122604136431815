import React from 'react'
import { Link } from 'gatsby'

// Components
import Layout from '../components/layouts/default'

const SecondPage = () => (
  <Layout>
  <div className="container">
    <Link to="/">Zur Startseite</Link>
    <h1>Impressum</h1>
    <h2>Angaben gemäß §5 TMG</h2>
    <p>
      <strong>Foodtoura</strong>
      <br />
      BlauPeter GmbH<br />
      Bahnhofstraße 28<br />
      24937 Flensbug<br />
      <br />
      Tel: 0461 - 80 72 85 10<br />
      <a href="mailto:hello@foodtoura.de">hello@foodtoura.de</a><br />
      <a href="https://www.foodtoura.de/">www.foodtoura.de</a>
    </p>
    <p>
      Amtsgericht Flensburg<br />
      HRB 16950 FL<br />
      Ust.IdNr. DE367166864<br />
      Geschäftsführerin: Wiebke Wall
    </p>
  </div>
  </Layout>
)

export default SecondPage
